import React from "react";
import { BrowserRouter } from "react-router-dom";
import AnimateRoutes from "./animate.route";
import { Helmet } from "react-helmet";
// import CustomizedTimeline from "../Components/timeline/timeline.component";

const PageRoutes = () => (
  <BrowserRouter>
    <Helmet>
      <title>ZEDBYTE</title>
      <meta
        name="description"
        content="We are one team with you !
Our goal is to provide the best
service for our clients."
      />
    </Helmet>
    <AnimateRoutes />
  </BrowserRouter>
);

export default PageRoutes;
