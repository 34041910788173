import React, { Suspense } from "react";
import "./solutions.page.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import ContentJson from "../../Assets/content.json";
// import timeline
import {
  StaticTimeline,
  DynamicTimeline,
  TemplateTimeline,
} from "../../Components/timeline/timeline.component";
import { useEffect } from 'react';

// Components imports
const Header = React.lazy(() => import('../../Components/header/header.component'));
const Footer = React.lazy(() => import('../../Components/footer/footer.component'));
const SolutionBox = React.lazy(() => import('../../Components/solutions_box/solution.component'));
const FrameworkBox = React.lazy(() => import('../../Components/framework_box/framwork.component'));

const Solutions = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  const [timeline, setTimeline] = useState(0);


  const changetimeline = (value) => {
    setTimeline(value);
  };
  return (
    <div className="solution-container">
      <Suspense fallback={<div className="suspense_style">ZEDBYTE Loading...</div>}>
        <Header />
        <Helmet>
          <title>ZEDBYTE - SOLUTIONS</title>
          <meta
            name="description"
            content="Zedbyte is a reputable web design firm that has successfully entered the international industry in order to dominate the regional market. Due to our talented web developers, we have a solid track record of producing high-quality site designs."
          />
        </Helmet>
        <motion.section
          id="top"
          className="solution-section-1-container"
          initial={{ width: 0 }}
          animate={{ width: "100%" }}
          exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
        >
          <div className="solution-section-1-top-container">
            <div className="solution-section-1-top-warp">
              <div className="solution-heading-1">
                Solutions we <br /> Provide
              </div>
              <div className="solution-content">
                In order to deliver the outcomes you desire, our team thinks
                broadly, aspires <br /> high, and works arduously.
              </div>
            </div>
          </div>
          <div className="solution-section-1-bottom-container">
            {Object.entries(ContentJson.solutions).map(([key, value]) => (
              <SolutionBox image={value.image} title={value.title} />
            ))}
          </div>
        </motion.section>


        <section className="solution-section-2-container" id="frameworks">
          <div className="solution-section-2-top-container">
            <div className="solution-section-2-top-warp">
              <div className="solution-heading-2">Frameworks we use</div>
              <div className="solution-content-2">
                A framework has become critical for the development and
                maintenance of <br /> websites. With a lot of choices available,
                it becomes extremely difficult to <br /> decide on one.
              </div>
            </div>
          </div>
          <div className="solution-section-2-bottom-container">
            {Object.entries(ContentJson.frameworks).map(([key, value]) => (
              <FrameworkBox image={value.image} title={value.title} />
            ))}
          </div>
        </section>

        <section className="solution-section-3-container">
          <div className="solution-section-3-top-container">
            <div className="solution-section-2-top-warp">
              <div className="solution-heading-2">How We Work</div>
              <div className="solution-content-2">
                We worked through the years to improve our development process,{" "}
                <br /> We blend the understanding of our customer's requests and
                deep user research.
                <div className="solution-content-2-button-warp">
                  <button
                    onClick={() => {
                      changetimeline(0);
                    }}
                    className="solution-button"
                  >
                    Static site
                  </button>
                  <button
                    onClick={() => {
                      changetimeline(1);
                    }}
                    className="solution-button"
                  >
                    Dynamic Site
                  </button>
                  <button
                    onClick={() => {
                      changetimeline(2);
                    }}
                    className="solution-button"
                  >
                    Template Site
                  </button>
                </div>
                <p className="home-heading-3">
                  Please click any of these to see the TIMELINE
                </p>
              </div>
            </div>
          </div>
          <div className="solution-section-3-bottom-container">
            {timeline === 0 ? (
              <StaticTimeline />
            ) : timeline === 1 ? (
              <DynamicTimeline />
            ) : (
              <TemplateTimeline />
            )}
          </div>
        </section>
        <Footer />
      </Suspense>
    </div>
  );
};
export default Solutions;
