export const siteMap = {
  HomePage: {
    title: "Home",
    path: "/",
    description: "zedbyte's home page",
  },
  PricingPage: {
    title: "Pricing",
    path: "/pricing",
    description: "zedbyte's pricing page",
  },
  ProjectPage: {
    title: "Projects",
    path: "/project",
    description: "zedbyte's project page",
  },
  SolutionPage: {
    title: "Solution",
    path: "/solution",
    description: "zedbyte's solution page",
  },
  ContactPage: {
    title: "contact",
    path: "/contact",
    description: "zedbyte's contact component",
  },
  TemplatePage: {
    title: "template",
    path: "/template/:id",
    description: "zedbyte's template component",
  },
  TimelinePage: {
    title: "timeline",
    path: "/timeline",
    description: "zedbyte's timeline component",
  },
  FooterComponent: {
    title: "footer",
    path: "/footer",
    description: "zedbyte's footer component",
  },
  CarouselComponent: {
    title: "Carousel",
    path: "/carousel",
    description: "zedbyte's Carousel component",
  },
};
