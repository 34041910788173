import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import Typography from "@mui/material/Typography";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import CodeIcon from "@mui/icons-material/Code";
import GitHubIcon from "@mui/icons-material/GitHub";
import ArchitectureIcon from '@mui/icons-material/Architecture';

export function StaticTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          variant="body2"
          color="error"
        >
          UI , UX , Prototyping
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="error">
            <ViewQuiltIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            FIGMA , ADOBE XD...
          </Typography>
          <Typography>
            As soon as we have the project description, <br /> we will begin
            working on the ui and ux designs <br /> and complete the project's
            overall prototyping.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="error"
        >
          FrontEnd Development
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="error">
            <CodeIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            FLUTTER, REACT, ANGULAR...
          </Typography>
          <Typography>
            Following approval of the design and prototypes,
            <br /> we begin frontend development using <br /> the appropriate
            framework.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="error"
        >
          Version Control
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="error">
            <GitHubIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            GITHUB, BITBUCKET..
          </Typography>
          <Typography>
            As soon as work begins, we commit and <br /> maintain our code
            securely, which helps us handle bugs <br /> and complete the project
            on schedule.
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="error"
        >
          Deployment
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="error">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            PLAYSTORE, AWS, GOOGLE CLOUD..
          </Typography>
          <Typography>
            We'll deploy the application ourselves <br /> after the project is
            finished.
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

export function DynamicTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          variant="body2"
          color="error"
        >
          UI , UX , Prototyping
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="error">
            <ViewQuiltIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            FIGMA , ADOBE XD...
          </Typography>
          <Typography>
            As soon as we have the project description, <br /> we will begin
            working on the ui and ux designs <br /> and complete the project's
            overall prototyping.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          variant="body2"
          color="error"
        >
          BackEnd Architecture
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="error">
            <ArchitectureIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            DRAW.IO , TERRASTRUCT...
          </Typography>
          <Typography>
            Once the prototyping is finished, we begin designing <br/> the backend architecture, including the server architecture, <br/> database architecture, and so on.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="error"
        >
          FrontEnd Development
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="error">
            <CodeIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            FLUTTER, REACT, ANGULAR...
          </Typography>
          <Typography>
            Following approval of the design and prototypes,
            <br /> we begin frontend development using <br /> the appropriate
            framework.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="error"
        >
          BackEnd Development
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="error">
            <CodeIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            PYTHON, JAVASCRIPT...
          </Typography>
          <Typography>
            Following approval of the design and prototypes,
            <br /> we begin backend development using <br /> the appropriate
            framework or language.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="error"
        >
          Version Control
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="error">
            <GitHubIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            GITHUB, BITBUCKET..
          </Typography>
          <Typography>
            As soon as work begins, we commit and <br /> maintain our code
            securely, which helps us handle bugs <br /> and complete the project
            on schedule.
          </Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="error"
        >
          Deployment
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="error">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            PLAYSTORE, AWS, GOOGLE CLOUD..
          </Typography>
          <Typography>
            We'll deploy the application ourselves <br /> after the project is
            finished.
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}


export function TemplateTimeline() {
    return (
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="error"
          >
            UI , UX , Prototyping
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="error">
              <ViewQuiltIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
             BOOM..!, DESIGNS ARE READY
            </Typography>
            <Typography>
                Simply choose the template you desire,<br/> then let's talk about customising.
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            variant="body2"
            color="error"
          >
            FrontEnd Development
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="error">
              <CodeIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              FLUTTER, REACT, ANGULAR...
            </Typography>
            <Typography>
              Following approval of the design and prototypes,
              <br /> we begin frontend development using <br /> the appropriate
              framework.
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            variant="body2"
            color="error"
          >
            Version Control
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="error">
              <GitHubIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              GITHUB, BITBUCKET..
            </Typography>
            <Typography>
              As soon as work begins, we commit and <br /> maintain our code
              securely, which helps us handle bugs <br /> and complete the project
              on schedule.
            </Typography>
          </TimelineContent>
        </TimelineItem>
  
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            variant="body2"
            color="error"
          >
            Deployment
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="error">
              <LaptopMacIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              PLAYSTORE, AWS, GOOGLE CLOUD..
            </Typography>
            <Typography>
              We'll deploy the application ourselves <br /> after the project is
              finished.
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    );
  }