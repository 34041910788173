import React, { Suspense } from "react";
import "./template.page.css";
import { motion } from "framer-motion";
import ContentJson from "../../Assets/content.json";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

// Components imports
const Header = React.lazy(() => import('../../Components/header/header.component'));
const Footer = React.lazy(() => import('../../Components/footer/footer.component'));
const CarouselSlider = React.lazy(() => import('./../../Components/slide_test/carousel.component'));

const Template = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  const location = useLocation();
  useEffect(() => {
    console.log(
      ContentJson.templates[
      parseInt(
        location.pathname.split("/")[location.pathname.split("/").length - 1]
      )
      ]
    );
  }, [location.pathname]);

  return (
    <motion.div
      className="template-main-container"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      <Suspense fallback={<div className="suspense_style">ZEDBYTE Loading...</div>}>
        <Header />
        <div className="template-heading-1">
          {
            ContentJson.templates[
              location.pathname.split("/")[
              location.pathname.split("/").length - 1
              ]
            ].title
          }
        </div>
        <CarouselSlider
          type={5}
          data={
            ContentJson.templates[
              location.pathname.split("/")[
              location.pathname.split("/").length - 1
              ]
            ].images
          }
          autoPlay={true}
          transitionDuration={2000}
          // slidesToSlide={0.25}
          // transition="all 1s linear"
          responsive={responsive}
        />
        <div className="template-heading-2">
          {
            ContentJson.templates[
              location.pathname.split("/")[
              location.pathname.split("/").length - 1
              ]
            ].description
          }
        </div>

        <div className="template-heading-4">Key points</div>
        <ul className="test">
          {ContentJson.templates[
            location.pathname.split("/")[location.pathname.split("/").length - 1]
          ].keypoints.map((key) => {
            return <li className="template-heading-3">{key}</li>;
          })}
        </ul>

        <Footer />
      </Suspense>
    </motion.div>
  );
};
export default Template;
