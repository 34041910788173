import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { siteMap } from "./sitemap.route";

import Home from "../Pages/home/home.page";
import Pricing from "../Pages/pricing/pricing.page";
import Projects from "../Pages/Projects/project.page";
import Solutions from "../Pages/solutions/solutions.page";
import Contact from "../Pages/contact/contact.page";
import Template from "../Pages/template/template.page";
// import Footer from "../Components/footer/footer.component";
// import Carousel from "../Components/slide_test/slide";

// import { AnimatePresence } from "framer-motion/dist/framer-motion";
import { AnimatePresence } from "framer-motion";

const AnimateRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route exact path={siteMap.HomePage.path} element={<Home />} />
        <Route exact path={siteMap.PricingPage.path} element={<Pricing />} />
        <Route exact path={siteMap.ProjectPage.path} element={<Projects />} />
        <Route exact path={siteMap.SolutionPage.path} element={<Solutions />} />
        <Route exact path={siteMap.ContactPage.path} element={<Contact />} />
        <Route exact path={siteMap.TemplatePage.path} element={<Template />} />
        {/* <Route exact path={siteMap.FooterComponent.path} element={<Footer />} />
      <Route exact path={siteMap.CarouselComponent.path} element={<Carousel />} /> */}
        {/* <Route exact path={siteMap.TimelinePage.path} element={<CustomizedTimeline />} /> */}
      </Routes>
    </AnimatePresence>
  );
};

export default AnimateRoutes;
