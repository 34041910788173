import React, { Suspense } from "react";
import "./home.page.css";
import {
  motion
} from "framer-motion";
import { useEffect } from 'react';

// Components imports
const Header = React.lazy(() => import('../../Components/header/header.component'));
const Footer = React.lazy(() => import('../../Components/footer/footer.component'));
const Why = React.lazy(() => import('./why_zedbyte/why.page'));
const Landing = React.lazy(() => import('./landing/landing'));
const HomeSolution = React.lazy(() => import('./solution_we_provide/solution'));
const ProjectsCarousel = React.lazy(() => import('./home_projects/project'));
const FrameWork = React.lazy(() => import('./home_frameworks/framework'));


const Home = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <motion.div
      className="home-container"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
    >
      <Suspense fallback={<div className="suspense_style">ZEDBYTE Loading...</div>}>
        <Header />
        <Landing />
        <Why />
        <HomeSolution />
        <ProjectsCarousel />
        <FrameWork />
        {/* <TestimonialsCarousel/> */}
        <Footer />
      </Suspense>
    </motion.div>
  );
};
export default Home;
