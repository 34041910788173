import React, { Suspense } from "react";
import "./project.page.css";
import soFar from "../../Assets/soFar.svg";
import { motion } from "framer-motion";
import ContentJson from "../../Assets/content.json";
import { useEffect } from 'react';
import { Helmet } from "react-helmet";


// Components imports
const Header = React.lazy(() => import('../../Components/header/header.component'));
const Footer = React.lazy(() => import('../../Components/footer/footer.component'));
const SoFarBox = React.lazy(() => import('../../Components/soFar_box/so_far_box.component'));
const ProjectBox = React.lazy(() => import('../../Components/projects_box/project.component'));
const SimpleBox = React.lazy(() => import('./../../Components/simple_box/simple_box.component'));



const Projects = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <motion.div
      className="project-container"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      <Suspense fallback={<div className="suspense_style">ZEDBYTE Loading...</div>}>
        <Header />
        {/* SEO stuffs */}
        <Helmet>
          <title>ZEDBYTE - PROJECTS</title>
          <meta
            name="description"
            content="we don't have any of your favourite superheroes like Superman, Captain
        America, or anyone else, we still manage to provide you with better
        service."
          />
        </Helmet>
        <section className="project-section-1-container">
          <div className="project-section-1-left">
            <img className="project-soFar-image" src={soFar} alt="" />
          </div>
          <div className="project-section-1-right">
            <div className="project-section-1-right-top-container">
              <div className="project-section-1-right-top-warp">
                <div className="project-heading-1">
                  So far, We have <br /> delivered
                </div>
              </div>
            </div>
            <div className="project-section-1-right-bottom-container">
              <div className="project-section-1-right-bottom-warp">
                <SoFarBox count="65+" description="Projects" />
                <SoFarBox count="35+" description="Clients" />
                <SoFarBox count="30+" description="Websites" />
                <SoFarBox count="20+" description="Mobile Applications" />
              </div>
            </div>
          </div>
        </section>
        <section className="project-section-2-container">
          <div className="project-section-2-top-container">
            <div className="project-heading-2">
              Some of our <br /> Projects
            </div>
          </div>
          <div className="project-section-2-bottom-container">
            {Object.entries(ContentJson.projects).map(([key, value]) => (
              <ProjectBox image={value.image} title={value.title} description={value.description} />
            ))}
          </div>
        </section>
        <section className="project-section-3-container">
          <div className="project-section-3-top-container">
            <div className="project-heading-3">Mini Projects</div>
          </div>
          <div className="project-section-3-bottom-container">
            {Object.entries(ContentJson.miniProjects).map(([key, value]) => (
              <SimpleBox
                title={value.title}
                description={value.description}
              />
            ))}
          </div>
        </section>
        <Footer />
      </Suspense>
    </motion.div>
  );
};
export default Projects;
