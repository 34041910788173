import React, { Suspense } from "react";
import "./pricing.page.css";
import { motion } from "framer-motion";
import ContentJson from "../../Assets/content.json";
import { useEffect } from 'react';
import { Helmet } from "react-helmet";

// Components imports
const Header = React.lazy(() => import('../../Components/header/header.component'));
const Footer = React.lazy(() => import('../../Components/footer/footer.component'));
const PricingBox = React.lazy(() => import('../../Components/pricing_box/pricing.component'));

const Pricing = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <motion.div
      className="price-container"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      <Suspense fallback={<div className="suspense_style">ZEDBYTE Loading...</div>}>

        <Header />
        {/* SEO stuffs */}
        <Helmet>
          <title>ZEDBYTE - PLANS</title>
          <meta
            name="description"
            content="Looking for a way to make more money online but don't know where to start?
        We offer a one-stop place for anything and everything you might need."
          />
        </Helmet>
        <div className="price-main-container">
          <div className="price-top-container">
            <div className="price-top-container-warp">
              <div className="price-heading-1">Plans</div>
              <div className="pricing-content">
                Looking for a way to make more money online but don't know where
                to start? <br /> We offer a one-stop place for anything and
                everything you might need.
              </div>
            </div>
          </div>
          <div className="price-bottom-container">
            {Object.entries(ContentJson.pricing).map(([key, value]) => (
              <PricingBox
                title={value.title}
                image={value.image}
                innerTitle={value.subtitle}
                innerContent={value.description}
              />
            ))}
          </div>
        </div>
        <Footer />
      </Suspense>
    </motion.div>
  );
};
export default Pricing;
