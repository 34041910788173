import React, { Suspense } from "react";
import "./contact.page.css";
import { motion } from "framer-motion";
import { useEffect } from 'react';
import { Helmet } from "react-helmet";

// Components imports
const Header = React.lazy(() => import('../../Components/header/header.component'));
const Footer = React.lazy(() => import('../../Components/footer/footer.component'));
const ContactForm = React.lazy(() => import('../../Components/contact_form/form.component'));

const Contact = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <motion.div
      className="contact-main-container"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      <Suspense fallback={<div className="suspense_style">ZEDBYTE Loading...</div>}>
        <Header />
        <Helmet>
          <title>ZEDBYTE - CONTACT</title>
          <meta
            name="description"
            content="Every collaboration starts with a discussion, Do not be hesitant to contact us; we are eager to assist you"
          />
        </Helmet>
        <ContactForm />
        <Footer />
      </Suspense>
    </motion.div>
  );
};
export default Contact;
